





































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import Yuyi from "../components/yuyi.vue";
import YuyiTree from "../components/yuyi-tree.vue";
import Shuxing from "../components/shuxing.vue";
import ShuxingTree from "../components/shuxing-tree.vue";
import Guanxi from "../components/guanxi.vue";
import GuanxiTree from "../components/guanxi-tree.vue";
import { GetCategory } from "@/request/schema";

@Component({
  components: {
    MainHeader,
    Empty,
    Yuyi,
    YuyiTree,
    Shuxing,
    ShuxingTree,
    Guanxi,
    GuanxiTree,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private tab: any = "语义类型";
  private search: any = "";
  private ifShowAddJiegou: any = false; // 添加结构弹框
  private addJiegouData: any = {};
  private currentTree: any = {}; // 当前选中的左边导航
  private category: any = [];
  private getCategory() {
    GetCategory(this).then((res: any) => {
      this.category = res;
    });
  }
  private goSearch() {
    if (!this.search) {
      this.$message.warning("请输入关键词搜索");
      return;
    }
    localStorage.setItem("searchTypeFilter", "");
    this.$router.push({
      path: "/main/mark/schema/search",
      query: {
        search: this.search,
      },
    });
  }
  private changeTab(val: any) {
    this.tab = val;
  }
  private addJiegou() {
    this.addJiegouData = {};
    this.ifShowAddJiegou = true;
    if (this.tab == "关系类型") {
      this.getCategory();
    }
  }
  private addJiegouSure() {
    if (this.tab == "关系类型" && !this.addJiegouData.category) {
      this.$message.warning("请选择类别");
      return;
    }
    if (!this.addJiegouData.name) {
      this.$message.warning("请输入" + this.tab + "名称！");
      return;
    }
    this.ifShowAddJiegou = false;
    if (this.tab == "语义类型") {
      (this.$refs.yuyiTree as any).add(this.addJiegouData);
    } else if (this.tab == "属性类型") {
      (this.$refs.shuxingTree as any).add(this.addJiegouData);
    } else if (this.tab == "关系类型") {
      (this.$refs.guanxiTree as any).add(this.addJiegouData);
    }
  }
  private updateCurrentTree(e: any) {
    this.currentTree = e;
  }
  private updateTree() {
    if (this.tab == "关系类型") {
      (this.$refs.guanxiTree as any).updateTree();
    }
  }
  private refresh(e: any) {
    this.changeTab(e["类型"]);
    setTimeout(() => {
      if (e["类型"] == "语义类型") {
        (this.$refs.yuyiTree as any).refresh(e);
      } else if (e["类型"] == "属性类型") {
        (this.$refs.shuxingTree as any).refresh(e);
      } else if (e["类型"] == "关系类型") {
        (this.$refs.guanxiTree as any).refresh(e);
      }
    }, 100);
  }
  mounted() {
    if (this.$route.query.data) {
      const d = JSON.parse(this.$route.query.data as any);
      this.tab = d["类型"];
    } else {
      // 给默认tab
      if (this.getIsShowQuanxian("schema语义类型")) {
        this.tab = "语义类型";
      } else if (this.getIsShowQuanxian("schema属性类型")) {
        this.tab = "属性类型";
      } else if (this.getIsShowQuanxian("schema关系类型")) {
        this.tab = "关系类型";
      }
    }
  }
}
