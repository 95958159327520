



























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetDaohang,
  AddDaohang,
  EditDaohang,
  DelDaohang,
  SortDaohang,
  EditDefinition,
} from "@/request/schema";
@Component({})
export default class Name extends Vue {
  private currentNode: any = {};
  private expandedNode: any = [];
  private id: any = 1000;
  private data: any = [];
  private defaultProps: any = {
    label: "域名称",
  };
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifShowAdd: any = false;
  private ifModify: any = false;
  private clickData: any = {}; // 点击的数据
  private add(data: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
    });
  }
  private refresh(d: any) {
    this.currentNode = {
      域id: d._id,
      域名称: d["名称"],
      类型: d["类型"],
    };
    this.getNav().then((res: any) => {
      this.init();
    });
  }
  private addSure() {
    if (!this.addJiegouData["域名称"]) {
      this.$message.warning("请输入语义类型名称！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
      name: this.addJiegouData["域名称"],
      域id: this.clickData["域id"],
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
      this.ifShowAdd = false;
    });
  }
  private append(data: any) {
    this.clickData = data;
    this.ifShowAdd = true;
    this.addJiegouData = {};
  }
  private handleCommand(e: any) {
    this.clickData = e.data;
    if (e.command == "删除") {
      this.$confirm(
        "您确定要删除该语义类型么？删除后该语义类型下的子结构也将全部删除。",
        "删除",
        {
          customClass: "commonConfirm",
        }
      )
        .then(() => {
          const params: any = {
            data: {
              _id: this.clickData["域id"],
            },
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          DelDaohang(this, params, loading).then((res: any) => {
            loading.close();
            this.$message.success("删除成功！");
            this.getNav().then((d: any) => {
              this.currentNode = e.node.parent.data;
              this.init();
            });
          });
        })
        .catch(() => {
          console.log("取消");
        });
    } else if (e.command == "编辑") {
      this.modifyData = e.data;
      if (!e.data["事件类型"]) {
        this.modifyData["事件类型"] = false;
      }
      if (!e.data["专题类型"]) {
        this.modifyData["专题类型"] = false;
      }
      this.ifModify = true;
    }
  }
  private modify() {
    if (!this.modifyData["域名称"]) {
      this.$message.warning("请输入语义类型名称！");
      return;
    }
    const params: any = {
      _id: this.clickData["域id"],
      name: this.modifyData["域名称"],
      事件类型: this.modifyData["事件类型"],
      专题类型: this.modifyData["专题类型"],
      定义: this.modifyData["定义"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditDefinition(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("编辑成功");
      this.ifModify = false;
      this.getNav().then((d: any) => {
        this.currentNode = {
          域id: this.currentNode["域id"],
          域名称: this.modifyData["域名称"],
          事件类型: this.modifyData["事件类型"],
          专题类型: this.modifyData["专题类型"],
          定义: this.modifyData["定义"],
        };
        this.$emit("updateCurrentTree", this.currentNode);
        this.init();
      });
    });
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      data: this.data,
    };
    SortDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getNav().then((d: any) => {
        this.currentNode = draggingNode.data;
        this.init();
      });
    });
  }
  private handleNodeClick(data: any, node: any) {
    this.currentNode = data;
    this.init();
  }
  private init() {
    if (this.$refs.yuyiTree) {
      (this.$refs.yuyiTree as any).setCurrentKey(this.currentNode);
      this.expandedNode = [this.currentNode["域id"]];
      this.$emit("updateCurrentTree", this.currentNode);
    }
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          type: "语义类型",
        },
      };
      GetDaohang(this, params, loading).then((res: any) => {
        loading.close();
        this.data = res;
        resolve(res);
      });
    });
  }
  private mounted() {
    if (this.$route.query.data) {
      const d = JSON.parse(this.$route.query.data as any);
      if (d["类型"] == "语义类型") {
        this.getNav().then((data: any) => {
          this.currentNode = {
            域id: d._id,
            域名称: d["名称"],
            类型: d["类型"],
          };
          this.init();
        });
      } else {
        this.getNav().then((d: any) => {
          this.currentNode = this.data[0];
          this.init();
        });
      }
    } else {
      this.getNav().then((d: any) => {
        if (!this.currentNode["域id"]) {
          this.currentNode = this.data[0];
          this.init();
        }
      });
    }
  }
}
